import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { User } from '#root/core/user/user.types';
import {
    map,
    Observable,
    ReplaySubject,
} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserStorageService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): User {
        //const user = JSON.parse(sessionStorage.getItem("userDetails")) as User;
        const user: User = {
            id: localStorage.getItem('idUser'),
            name: localStorage.getItem('fullName'),
            email: localStorage.getItem('email'),
            avatar: localStorage.getItem('avatar'),
            status: 'Logueado'
        }
        return user;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): User {
        //const user = JSON.parse(sessionStorage.getItem("userDetails")) as User;
        const user: User = {
            id: localStorage.getItem('idUser'),
            name: localStorage.getItem('fullName'),
            email: localStorage.getItem('email'),
            avatar: localStorage.getItem('avatar'),
            status: 'Logueado'
        }
        return user;
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', { user }).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }
}


const user: User = {
    id: localStorage.getItem('idUser'),
    name: localStorage.getItem('fullName'),
    email: `${localStorage.getItem('userName')}+'@grupoplatino.hn'`,
    avatar: localStorage.getItem('avatar'),
    status: 'Logueado'
}