import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';

import {
  LanguagesModule,
} from '#root/core/layout/common/languages/languages.module';
import {
  MessagesModule,
} from '#root/core/layout/common/messages/messages.module';
import {
  NotificationsModule,
} from '#root/core/layout/common/notifications/notifications.module';
import {
  QuickChatModule,
} from '#root/core/layout/common/quick-chat/quick-chat.module';
import { SearchModule } from '#root/core/layout/common/search/search.module';
import { UserModule } from '#root/core/layout/common/user/user.module';
import {
  DenseLayoutComponent,
} from '#root/core/layout/layouts/vertical/dense/dense.component';
import { SharedModule } from '#root/shared/shared.module';

import { FuseFullscreenModule } from '@fuse/components/fullscreen';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { FuseNavigationModule } from '@fuse/components/navigation';

@NgModule({
  declarations: [
    DenseLayoutComponent
  ],
  imports: [
    HttpClientModule,
    RouterModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    FuseFullscreenModule,
    FuseLoadingBarModule,
    FuseNavigationModule,
    LanguagesModule,
    MessagesModule,
    NotificationsModule,
    QuickChatModule,
    SearchModule,
    UserModule,
    SharedModule
  ],
  exports: [
    DenseLayoutComponent
  ]
})
export class DenseLayoutModule {
}
