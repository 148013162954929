import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import {
  LanguagesComponent,
} from '#root/core/layout/common/languages/languages.component';
import { SharedModule } from '#root/shared/shared.module';

@NgModule({
    declarations: [
        LanguagesComponent
    ],
    imports: [
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        SharedModule
    ],
    exports: [
        LanguagesComponent
    ]
})
export class LanguagesModule {
}
