import { Injectable } from '@angular/core';

import { cloneDeep } from 'lodash-es';
import defaultNavigation from '../navigation/data';

import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';

@Injectable({
    providedIn: 'root'
})
export class NavigationMockApi {
    private _defaultNavigation: FuseNavigationItem[];


    /**
     * Constructor
     */
    constructor(private _fuseMockService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockService
            .onGet('api/common/navigation')
            .reply(() => {

                this._defaultNavigation = defaultNavigation();

                // Return the response
                return [
                    200,
                    {
                        default: cloneDeep(this._defaultNavigation),
                    }
                ];
            });
    }
}
