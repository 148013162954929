import { FuseNavigationItem } from '@fuse/components/navigation';

export default function ManagerNav() {

    const nav: FuseNavigationItem[] = [
        {
            id: 'crm',
            title: 'CRM',
            subtitle: 'Interactua con los clientes',
            type: 'group',
            icon: 'heroicons_outline:home',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'basic',
                    icon: 'heroicons_outline:chart-pie',
                    link: '/dashboard'
                },
                {
                    id: 'crm.clientes',
                    title: 'Clientes',
                    type: 'basic',
                    icon: 'heroicons_outline:users',
                    link: '/crm/customers'
                },
                // {
                //     id: 'crm.opportunity',
                //     title: 'Oportunidades',
                //     type: 'basic',
                //     icon: 'heroicons_outline:shopping-cart',
                //     link: '/crm/opportunity'
                // },
                // {
                //     id: 'crm.wishList',
                //     title: 'Lista de Deseos',
                //     type: 'basic',
                //     icon: 'heroicons_outline:sparkles',
                //     link: '/crm/wishList'
                // },
                // {
                //     id: 'activities',
                //     title: 'Calendario de Actividades',
                //     type: 'basic',
                //     icon: 'heroicons_outline:calendar',
                //     link: '/crm/activities'
                // },
                // {
                //     id: 'customersWallet',
                //     title: 'Asesores de venta',
                //     type: 'basic',
                //     icon: 'heroicons_outline:briefcase',
                //     link: '/crm/salesAdvisors'
                // },
                // {
                //     id: 'usersProspects',
                //     title: 'Leads',
                //     type: 'basic',
                //     icon: 'heroicons_outline:user',
                //     link: '/crm/prospects'
                // },
                {
                    id: 'reports',
                    title: 'Reportes',
                    type: 'collapsable',
                    icon: 'heroicons_outline:folder-open',
                    children: [
                //         {
                //             id: 'report.customers_advisor',
                //             title: 'Clientes por asesores',
                //             type: 'basic',
                //             link: '/crm/report/customers-advisor'
                //         },
                        {
                            id: 'report.quoted_products_customer',
                            title: 'Productos cotizados por clientes',
                            type: 'basic',
                            link: '/crm/report/quoted-products-client'
                        },
                //         {
                //             id: 'report.advisor_goal',
                //             title: 'Metas de Asesores',
                //             type: 'basic',
                //             link: '/crm/report/advisor-goal'
                //         },
                        {
                            id: 'report.top_quote',
                            title: 'Productos mas cotizados',
                            type: 'basic',
                            link: '/crm/report/top-quote'
                        },
                        {
                            id: 'report.top_quote_by_cliente',
                            title: 'Clientes interesados por producto',
                            type: 'basic',
                            link: '/crm/report/clients-by-quoted-product'
                        },
                        {
                            id: 'report.top_sold',
                            title: 'Productos mas vendidos',
                            type: 'basic',
                            link: '/crm/report/top-sold'
                        },
                //         {
                //             id: 'report.advisor_activities',
                //             title: 'Actividades de los asesores',
                //             type: 'basic',
                //             link: '/crm/report/advisor-activities'
                //         },
                //         {
                //             id: 'report.opportunity_origins',
                //             title: 'Origenes de oportunidades',
                //             type: 'basic',
                //             link: '/crm/report/opportunity-origins'
                //         },
                //         {
                //             id: 'report.master',
                //             title: 'Maestro',
                //             type: 'basic',
                //             link: '/crm/report/master'
                //         },
                //         {
                //             id: 'report.master-leads',
                //             title: 'Maestro Leads',
                //             type: 'basic',
                //             link: '/crm/report/master-leads'
                //         },
                        // {
                        //     id: 'report.master-clients',
                        //     title: 'Maestro Clientes',
                        //     type: 'basic',
                        //     link: '/crm/report/master-client'
                        // },
                        {
                            id: 'report.master-products',
                            title: 'Maestro Productos',
                            type: 'basic',
                            link: '/crm/report/master-product'
                        },
                        {
                            id: 'report.top_buyed_by_cliente',
                            title: 'Compradores por producto',
                            type: 'basic',
                            link: '/crm/report/clients-by-sold-product'
                        }
                    ]
                },
            ],
        },
        {
            id:'accounting',
            title: 'Contabilidad',
            subtitle: 'Administra todos los ejercicios contables',
            type: 'group',
            icon: 'heroicons_outline:banknotes',
            children:
            [
                {
                    id: 'accounting.quotations',
                    title: 'Cotizaciones',
                    type: 'basic',
                    icon: 'heroicons_outline:document-minus',
                    link: '/accounting/quotations'
                },
                {
                    id: 'accounting.invoices',
                    title: 'Facturas',
                    type: 'basic',
                    icon: 'heroicons_outline:document-text',
                    link: '/accounting/invoices'
                },
                {
                    id: 'accounting.dailyClose',
                    title: 'Cierres Diarios',
                    type: 'basic',
                    icon: 'heroicons_outline:banknotes',
                    link: '/accounting/daily-closes'
                },
                // {
                //     id: 'accounting.buys',
                //     title: 'Compras',
                //     type: 'collapsable',
                //     icon: 'heroicons_outline:folder-open',
                //     children: [
                //         {
                //             id: 'accounting.purchase-orders',
                //             title: 'Ordenes de Compra',
                //             type: 'basic',
                //             icon: 'heroicons_outline:document-text',
                //             link: '/accounting/purchase-orders'
                //         },
                //         {
                //             id: 'accounting.purchase-bills',
                //             title: 'Facturas de Compra',
                //             type: 'basic',
                //             icon: 'heroicons_outline:document-text',
                //             link: '/accounting/purchase-bills'
                //         },
                //         {
                //             id: 'accounting.non-billable-expenses',
                //             title: 'Gastos no Facturables',
                //             type: 'basic',
                //             icon: 'heroicons_outline:document-text',
                //             link: '/accounting/non-billable-expenses'
                //         },
                    //         {
                        //     id: 'accounting.monthly-purchase-declaration',
                        //     title: 'Declaración Mensual de Compras',
                        //     type: 'basic',
                        //     icon: 'heroicons_outline:document-text',
                        //     link: '/accounting/monthly-purchase-declaration'
                        // }
                //     ]
                // }
                {
                    id: 'accounting.accountsReceivables',
                    title: 'CxC (Cuentas por Cobrar)',
                    type: 'basic',
                    icon: 'heroicons_outline:banknotes',
                    link: '/accounting/accounts-receivables'
                },
            ]
        },
        {
            id: 'inventory',
            title: 'Inventario',
            subtitle: 'Administra los productos y proveedores',
            type: 'group',
            icon: 'heroicons_outline:home',
            children: [
                {
                    id: 'inventory.inventory-input',
                    title: 'Entrada de Inventario',
                    type: 'basic',
                    icon: 'heroicons_outline:archive-box-arrow-down',
                    link: '/inventory/inventory-input'
                },
                {
                    id: 'inventory.products',
                    title: 'Productos',
                    type: 'basic',
                    icon: 'heroicons_outline:squares-plus',
                    link: '/inventory/products'
                },
                {
                    id: 'inventory.warehouses',
                    title: 'Almacenes',
                    type: 'basic',
                    icon: 'heroicons_outline:swatch',
                    link: '/inventory/warehouses'
                },
                {
                    id: 'inventory.supplier',
                    title: 'Proveedores',
                    type: 'basic',
                    icon: 'heroicons_outline:user-group',
                    link: '/inventory/suppliers'
                },
                {
                    id: 'inventory.brand',
                    title: 'Marcas',
                    type: 'basic',
                    icon: 'heroicons_outline:hashtag',
                    link: '/inventory/brands'
                },
                {
                    id: 'inventory.categoryProducts.category',
                    title: 'Categorías',
                    type: 'basic',
                    icon: 'heroicons_outline:tag',
                    link: '/inventory/categoryProducts/categories'

                },
                {
                    id: 'inventory.categoryProducts.subcategory',
                    title: 'Sub categorías',
                    type: 'basic',
                    icon: 'heroicons_outline:tag',
                    link: '/inventory/categoryProducts/subcategories'

                },
                // {
                //     id: 'inventory.characteristic',
                //     title: 'Caracteristicas',
                //     type: 'basic',
                //     icon: 'heroicons_outline:search-circle',
                //     link: '/inventory/characteristics'
                // },
            ]
        },
        {
            id: 'globalConfig',
            title: 'Configuraciones Globales',
            subtitle: 'Configura datos, tipos de datos y estados',
            type: 'group',
            icon: 'heroicons_outline:home',
            children: [
                // {
                //     id: "globalConfig.documentType",
                //     title: "Tipos de Documentos",
                //     type: "basic",
                //     icon: "heroicons_outline:document",
                //     link: '/globalConfig/documentType'
                // },
                // {
                //     id: "globalConfig.typeOrigins",
                //     title: "Tipos de Origen",
                //     type: "basic",
                //     icon: "mat_outline:account_tree",
                //     link: '/globalConfig/origin/typeOrigins'
                // },
                // {
                //     id: "globalConfig.typeActivity",
                //     title: "Tipos de Actividades",
                //     type: "basic",
                //     icon: "heroicons_outline:calendar",
                //     link: '/globalConfig/typeActivity'
                // },
                {
                    id: 'globalConfig.measureUnit',
                    title: 'Unidades de Medida',
                    type: 'basic',
                    icon: 'heroicons_outline:variable',
                    link: '/globalConfig/measureUnit'
                },
                {
                    id: 'ecommerce.cai',
                    title: 'CAIs',
                    type: 'basic',
                    icon: 'heroicons_outline:office-building',
                    link: '/ecommerce/cai'
                },
                {
                    id: "globalConfig.prefix",
                    title: "Prefijos",
                    type: "basic",
                    icon: "heroicons_outline:document",
                    link: '/globalConfig/prefix'
                },
                {
                    id: "globalConfig.tax",
                    title: "Impuestos",
                    type: "basic",
                    icon: "heroicons_outline:scale",
                    link: '/globalConfig/tax'
                },
                {
                    id: "globalConfig.discount",
                    title: "Descuentos",
                    type: "basic",
                    icon: "heroicons_outline:percent-badge",
                    link: '/globalConfig/discount'
                },
                {
                    id: 'globalConfig.users',
                    title: 'Usuarios',
                    type: 'basic',
                    icon: 'heroicons_outline:users',
                    link: '/globalConfig/users'
                }
                // {
                //     id: 'globalConfig.winReasons',
                //     title: 'Razones de ganancia',
                //     type: 'basic',
                //     icon: 'heroicons_outline:trending-up',
                //     link: '/globalConfig/winReasons'
                // },
                // {
                //     id: 'globalConfig.lossReasons',
                //     title: 'Razones de perdida',
                //     type: 'basic',
                //     icon: 'heroicons_outline:trending-down',
                //     link: '/globalConfig/lossReasons'
                // },
            ]
        }
    ];

    return nav;
}