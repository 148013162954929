import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Resolve,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import {
  BranchesOfficeResponse,
  CategoriesResponse,
  GeneralFinanceInformationResponse,
  GoalMetricsResponse,
  LostOpportunityMetricResponse,
  OpportunitiesResponse,
  OpportunityDeparmentMetricResponse,
  OpportunityReasonMetricsResponse,
  OpportunityResumeMetricResponse,
  OpportunityStepMetricResponse,
  ProductSoldComparativeResponse,
  ProspectStepMetricResponse,
  ProspectsMetricResponse,
  WinLossReasonsMetricResponse
} from '#interfaces/http-response/responses/http-responses';
import {
  BranchOfficeService,
} from '#services/branch-office/branch-office.service';
import { CategoryService } from '#services/category/category.service';
import {
  DashboardService,
} from '#services/dashboard/dashboard.service';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

const startDate = new Date(Date.now() - 864e5 * 90).toISOString();
const endDate = new Date().toISOString();


@Injectable({ providedIn: 'root' })
export class DashboardHomeGuard implements CanActivate {
  constructor(private _router: Router) { }

  canActivate() {
    const role = localStorage.getItem('rol');
    if (role === 'SalesAdvisor') this._router.navigate(['/', 'dashboard', 'advisor']);
    if (role === 'CommunityManager') this._router.navigate(['/', 'dashboard', 'merch']);

    return true;
  }
}

@Injectable({
  providedIn: "root",
})
export class DashboardByRoleResolver implements CanActivate {
  constructor(private _router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkUserRole();
  }

  checkUserRole(): boolean {
    if (localStorage.getItem("rol") == "SalesAdvisor") {
      return true;
    }

    if (localStorage.getItem("rol") == "CommunityManager") {
      return true;
    }

    else if (
      localStorage.getItem("rol") === "SuperAdmin" ||
      localStorage.getItem("rol") === "Admin" ||
      localStorage.getItem("rol") == "Manager"
    ) {
      return true;
    } else if (localStorage.getItem("rol") === "AfterSale") {
      this._router.navigate([`/rootcloud/dashboard`]);
      return false;
    } else {
      this._router.navigate([`/inventory/products`]);
      return false;
    }
  }
}

@Injectable({
  providedIn: "root",
})
export class DashboardOpportunityResumeMetricResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(private dashboardService: DashboardService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<OpportunityResumeMetricResponse> {
    return this.dashboardService.OpportunitiesResumeMetric(this.dashboardService.currenBranchOfficeId);
  }
}

@Injectable({
  providedIn: "root",
})
export class DashboardDeparmentMetricResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(private dashboardService: DashboardService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<OpportunityDeparmentMetricResponse> {
    return this.dashboardService.OpportunitiesDeparmentMetric(this.dashboardService.currenBranchOfficeId);
  }
}

@Injectable({
  providedIn: "root",
})
export class DashboardGlobalGoalMetricResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(private dashboardService: DashboardService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<GoalMetricsResponse> {
    return this.dashboardService.GlobalGoalMetric(this.dashboardService.currenBranchOfficeId);
  }
}

@Injectable({
  providedIn: "root",
})
export class DashboardOpportunityWinLossReasonMetricResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(private dashboardService: DashboardService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<WinLossReasonsMetricResponse> {
    return this.dashboardService.OpportunitiesWinLossResumeMetric(this.dashboardService.currenBranchOfficeId);
  }
}

@Injectable({
  providedIn: "root",
})
export class DashboardOpportunityStepMetricResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(private dashboardService: DashboardService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<OpportunityStepMetricResponse> {
    return this.dashboardService.OpportunityStepMetrics(startDate, endDate, this.dashboardService.currenBranchOfficeId);
  }
}

@Injectable({
  providedIn: "root",
})
export class DashboardOpportunityFunnelStepMetricResolver
  implements Resolve<any>
{
  /**
   * Constructor
   */
  constructor(private dashboardService: DashboardService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<OpportunityStepMetricResponse> {
    return this.dashboardService.OpportunityFunnelStepMetrics(
      startDate,
      endDate,
      this.dashboardService.currenBranchOfficeId
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class DashboardOpportunityLossReasonMetricResolver
  implements Resolve<any>
{
  /**
   * Constructor
   */
  constructor(private dashboardService: DashboardService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<OpportunityReasonMetricsResponse> {
    return this.dashboardService.OpportunityLossReasonMetrics(
      startDate,
      endDate,
      this.dashboardService.currenBranchOfficeId
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class DashboardOpportunityWinReasonMetricResolver
  implements Resolve<any>
{
  /**
   * Constructor
   */
  constructor(private dashboardService: DashboardService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<OpportunityReasonMetricsResponse> {
    return this.dashboardService.OpportunityWinReasonMetrics(
      startDate,
      endDate,
      this.dashboardService.currenBranchOfficeId
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class DashboardLostOpportunityMetricResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(private dashboardService: DashboardService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<LostOpportunityMetricResponse> {
    let date = DateTime.now();
    return this.dashboardService.LostOpportunityMetrics(date.year, this.dashboardService.currenBranchOfficeId);
  }
}

@Injectable({
  providedIn: "root",
})
export class DashboardOpportunityOriginMetricResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(private dashboardService: DashboardService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<OpportunityReasonMetricsResponse> {
    return this.dashboardService.OpportunityOriginMetrics(this.dashboardService.currenBranchOfficeId);
  }
}

@Injectable({
  providedIn: "root",
})
export class DashboardProspectsMetricResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(private dashboardService: DashboardService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ProspectsMetricResponse> {
    return this.dashboardService.prospectsMetric(this.dashboardService.currenBranchOfficeId, startDate, endDate);
  }
}

@Injectable({
  providedIn: "root",
})
export class DashboardTopOpportunityMetricResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(private dashboardService: DashboardService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<OpportunitiesResponse> {
    return this.dashboardService.TopOpportunitiesMetric(this.dashboardService.currenBranchOfficeId);
  }
}

@Injectable({
  providedIn: "root",
})
export class DashboardOpportunityAdvisorBranchesResolver
  implements Resolve<any>
{
  /**
   * Constructor
   */
  constructor(private branchService: BranchOfficeService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<BranchesOfficeResponse> {
    return this.branchService.getAll(0, 0, "", "", "", true);
  }
}

@Injectable({
  providedIn: "root",
})
export class DashboardProspectStepMetricResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(private dashboardService: DashboardService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ProspectStepMetricResponse> {
    return this.dashboardService.ProspectStepsMetric(startDate,
      endDate, null);
  }
}

@Injectable({
  providedIn: "root",
})
export class DashboardProductSoldComparativeMetricResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(private dashboardService: DashboardService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ProductSoldComparativeResponse> {
    return this.dashboardService.ProductSoldComparative();
  }
}

@Injectable({
  providedIn: "root",
})
export class DashboardCategoriesResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(private categoryService: CategoryService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<CategoriesResponse> {
    return this.categoryService.getCategories(0, 10, "", "", "", true);
  }
}
@Injectable({
  providedIn: "root",
})
export class GeneralFinanceInformationResolver implements Resolve<any> {
  constructor(private _dashboardService: DashboardService) { }


  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<GeneralFinanceInformationResponse> {
    return this._dashboardService.GeneralFinanceInformation();
  }
}