import { Component } from '@angular/core';

import { AlertService } from '#utils/alert-service/alert-service.service';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppVersionService } from './shared/services/version';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor(private alertService: AlertService, private matDialog: MatDialog, private _appVersionService: AppVersionService) {
        this.changelog();
    }

    errorMessage$: Observable<{ message: string, errors: Array<string> | null }> = this.alertService.message$;
    updateMessage$: Observable<string> = this.alertService.updateMessage$
    saveMessage$: Observable<string> = this.alertService.saveMessage$


    public async changelog() {
        /* if (await this._appVersionService.outdated()) {
            this.matDialog.open(VersionChangelogComponent, {
                width: '100%',
                maxWidth: '600px',
                maxHeight: '90vh',
                disableClose: true,
            })
        } */
    }
}
