import { NgModule } from '@angular/core';

import {
  SettingsModule,
} from '#root/core/layout/common/settings/settings.module';
import { LayoutComponent } from '#root/core/layout/layout.component';
import {
  DenseLayoutModule,
} from '#root/core/layout/layouts/vertical/dense/dense.module';
import { SharedModule } from '#root/shared/shared.module';

import { EmptyLayoutModule } from './layouts/empty/empty.module';

const layoutModules = [DenseLayoutModule, EmptyLayoutModule];

@NgModule({
  declarations: [LayoutComponent],
  imports: [SharedModule, SettingsModule, ...layoutModules],
  exports: [LayoutComponent, ...layoutModules],
})
export class LayoutModule { }
